import { gql } from 'graphql-tag'
import { Shopify } from '../types'
import {
  fragment,
  ResponseType as Cart,
  unwrapResponse as unwrapCart,
} from './cart'

export const query = gql`
  mutation cartLinesAdd(
    $cartId: ID!
    $lines: [CartLineInput!]!
    $lng: LanguageCode!
  ) @inContext(language: $lng) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...Cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${fragment}
`

export type VariableType = {
  cartId: string
  lines: {
    quantity: number
    merchandiseId: string
    attributes?: Shopify.Attribute[]
  }[]
  lng: string
}

export type ResponseType =
  | {
      cart: Cart | undefined
      userErrors: Shopify.Error[]
    }
  | undefined

export const unwrapResponse = (response: any): ResponseType => {
  let cart
  let userErrors = []

  if (response.cart) {
    cart = unwrapCart(response.cart)
  }

  if (response.userErrors) {
    userErrors = response.userErrors
  }

  return { cart, userErrors }
}
