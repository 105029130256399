import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { AuthState } from './auth'
import { UserState } from './user'
import { CartState } from './cart'
import { ConfiguratorState } from './configurator'
import {
  isoToLanguage,
  isoToRegion,
  isoToCurrency,
  slugToIso,
  isoToSlug,
} from '~/services/i18n'

import appConfig from '~/app.config'

export type RootState = {
  auth: AuthState
  user: UserState
  i18n: { locale: string; showChangeLocale: boolean }
  cart: CartState
  configurator: ConfiguratorState
}

export const state = () =>
  ({
    i18n: {
      locale: appConfig.defaultLocale, // set by INIT_LOCALE_SSR in nuxt config
      showChangeLocale: false,
    },
  } as RootState)

export const getters: GetterTree<RootState, RootState> = {
  region: (_state, _getters, rootState) => {
    const locale = slugToIso(rootState.i18n.locale)
    return locale && locale.includes('-')
      ? isoToRegion(locale)
      : isoToRegion(appConfig.defaultLocale)
  },
  language: (_state, _getters, rootState) => {
    const locale = slugToIso(rootState.i18n.locale)
    return locale && locale.includes('-')
      ? isoToLanguage(locale)
      : isoToLanguage(appConfig.defaultLocale)
  },
  currency: (_state, _getters, rootState) => {
    const locale = slugToIso(rootState.i18n.locale)
    return locale && locale.includes('-')
      ? isoToCurrency(locale)
      : isoToCurrency(appConfig.defaultLocale)
  },
  unitSystem: (_state, getters) => {
    return appConfig.regions.find((r) => r.id === getters.region)!.units
  },
  socials: (_state, getters) => {
    return (
      (appConfig.socialMedia as Record<string, Record<string, string>>)[
        getters.region === 'US' ? 'en-US' : getters.language
      ] || {}
    )
  },
}

export const mutations: MutationTree<RootState> = {
  INIT_LOCALE_SSR(state, locale) {
    state.i18n.locale = locale
  },
  SHOW_CHANGE_LOCALE(state, open) {
    state.i18n.showChangeLocale = open
  },
}

export const actions: ActionTree<RootState, RootState> = {
  reload(_, iso: string) {
    window.location.replace(this.switchLocalePath(isoToSlug(iso)))
  },
  changeLocale({ commit }, open: boolean) {
    commit('SHOW_CHANGE_LOCALE', open)
  },
  nuxtServerInit(_) {},
}
