import { gql } from 'graphql-tag'
import { Shopify } from '../types'
import {
  fragment,
  ResponseType as Cart,
  unwrapResponse as unwrapCart,
} from './cart'

export const query = gql`
  mutation cartCreate($cartInput: CartInput, $lng: LanguageCode!)
  @inContext(language: $lng) {
    cartCreate(input: $cartInput) {
      cart {
        ...Cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${fragment}
`

export type VariableType = {
  cartInput: {
    lines: {
      quantity: number
      merchandiseId: string
      attributes?: Shopify.Attribute[]
    }[]
  }
  lng: string
}

export type ResponseType =
  | {
      cart: Cart | undefined
      userErrors: Shopify.Error[]
    }
  | undefined

export const unwrapResponse = (response: any): ResponseType => {
  let cart
  let userErrors = []

  if (response.cart) {
    cart = unwrapCart(response.cart)
  }

  if (response.userErrors) {
    userErrors = response.userErrors
  }

  return { cart, userErrors }
}
