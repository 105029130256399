import { gql } from 'graphql-tag'
import {
  fragment,
  ResponseType as Cart,
  unwrapResponse as unwrapCart,
} from './cart'

export const query = gql`
  query cart($cartId: ID!, $lng: LanguageCode!) @inContext(language: $lng) {
    cart(id: $cartId) {
      ...Cart
    }
  }
  ${fragment}
`

export type VariableType = {
  cartId: string
  lng: string
}

export type ResponseType = Cart | undefined

export const unwrapResponse = (response: any): ResponseType => {
  if (response.cart) {
    return unwrapCart(response.cart)
  }
}
