import { ActionTree, MutationTree } from 'vuex'
import cookie from 'js-cookie'
import * as jose from 'jose'
// eslint-disable-next-line import/named
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  fusionauthRefresh,
  FusionauthRefreshResponse,
  fusionAuthSignaturePublicKeys,
  fusionauthSocialSignOn,
  FusionauthSocialSignOnResponse,
  fusionAuthSocialSignOnStartUrl,
  fusionauthVerifyCode,
  FusionauthVerifyCodeResponse,
} from '~/api/fusionauth'
import {
  pyramidSignUp,
  PyramidSignUpResponse,
  pyramidSignIn,
  PyramidSignInResponse,
  pyramidConfig,
  PyramidConfigResponse,
  PyramidPasswordResetResponse,
  pyramidPasswordResetStart,
  pyramidPasswordResetEnd,
  fusionAuthEmailVerify,
} from '~/api/pyramid'
import appConfig from '~/app.config'
import { generatePkcePair } from '~/services/pkce'
import { RootState } from '~/store'
import { GetRequestConfig } from '~/api/internal'

export type JWTPayload = {
  email: string
  preferredLanguage: string
  roles: ('Basic' | 'Premium' | 'VIP')[]
  signupInstant: number
  sub: string
  legacyPaypal?: string
}

export type AuthState = {
  mail: string | undefined
  token: string | undefined
  refreshToken: string | undefined
  tokenContent: JWTPayload | undefined
  isLoading: boolean
  subscriptionCompleted: boolean
}

export const state = () =>
  ({
    mail: undefined,
    token: undefined,
    refreshToken: undefined,
    tokenContent: undefined,
    isLoading: true,
    subscriptionCompleted: false,
  } as AuthState)

export const mutations: MutationTree<AuthState> = {
  login(state, { mail, token, refreshToken }) {
    state.mail = mail ?? state.mail
    state.token = token
    state.refreshToken = refreshToken ?? ''
  },
  setTokenContent(state, { tokenContent }) {
    state.tokenContent = tokenContent
  },
  logout(state) {
    state.mail = undefined
    state.token = undefined
    state.refreshToken = undefined
    state.tokenContent = undefined
  },
  load(state) {
    state.isLoading = true
  },
  finishLoad(state) {
    state.isLoading = false
  },
  subscriptionCompleted(state) {
    state.subscriptionCompleted = true
  },
}

const getVerifiedTokenContent = async (
  token: string,
  $axios: NuxtAxiosInstance
) => {
  const pc = fusionAuthSignaturePublicKeys()
  const response = await $axios.$get(pc.url, pc.config)
  for (const i in response.keys)
    try {
      const key = await jose.importJWK(response.keys[i])
      const { payload } = await jose.jwtVerify(token, key)
      return payload
    } catch {}
  return undefined
}

export const actions: ActionTree<AuthState, RootState> = {
  async trialPeriodValidUntil({ state, rootState }): Promise<Date | undefined> {
    if (!state.tokenContent) return undefined
    try {
      const { url, config } = pyramidConfig({
        locale: rootState.i18n.locale,
      })
      const response: PyramidConfigResponse = await this.$axios.$get(
        url,
        config
      )

      const signupTimestamp = state.tokenContent.signupInstant * 1000

      const date = new Date(signupTimestamp)
      date.setDate(date.getDate() + response.trial_period_days)
      return date
    } catch (e) {
      throw { type: 'unknown', name: 'DateError' } // eslint-disable-line no-throw-literal
    }
  },
  async signUp(
    { rootState },
    { mail, region, language, didBuyBellicon, extendedTrial, password }
  ): Promise<PyramidSignUpResponse> {
    let response
    try {
      const { url, data, config } = pyramidSignUp({
        locale: rootState.i18n.locale,
        mail,
        region,
        language,
        didBuyBellicon,
        password,
        extendedTrial,
      })
      response = await this.$axios.$post(url, data, config)
      console.debug('res in signup', response)
      return response
    } catch (e) {
      throw { type: 'unknown', name: 'AuthError' } // eslint-disable-line no-throw-literal
    }
    // if (response.didFail) throw { type: 'failed', name: 'AuthError' } // eslint-disable-line no-throw-literal
  },
  async signIn(
    { rootState, commit, dispatch },
    { mail, password }
  ): Promise<PyramidSignInResponse> {
    let response: PyramidSignInResponse
    try {
      const { url, data, config } = pyramidSignIn({
        locale: rootState.i18n.locale,
        mail,
        password,
      })
      response = await this.$axios.$post(url, data, config)
    } catch (e) {
      throw { type: 'unknown', name: 'AuthError' } // eslint-disable-line no-throw-literal
    }
    if (response.user) {
      commit('login', {
        mail: response.user.email,
        token: response.token,
        refreshToken: response.refreshToken,
      })

      commit('setTokenContent', {
        tokenContent: await getVerifiedTokenContent(
          response.token,
          this.$axios
        ),
      })

      await Promise.all([
        dispatch('user/getVideoStars', undefined, { root: true }),
        dispatch('user/getActivePlan', undefined, { root: true }),
      ])
    }
    // if (response.didFail) throw { type: 'failed', name: 'AuthError' } // eslint-disable-line no-throw-literal
    return response
  },
  async passwordResetStart(
    { rootState },
    { mail }
  ): Promise<PyramidPasswordResetResponse> {
    try {
      const { url, data, config } = pyramidPasswordResetStart({
        locale: rootState.i18n.locale,
        mail,
      })
      const response = await this.$axios.$post(url, data, config)
      console.debug('res in passwordReset', response)
      return response
    } catch (e: any) {
      // Weitergabe des ursprünglichen Fehlers mit zusätzlichen Details
      if (e.response) {
        // Falls vorhanden, den HTTP-Statuscode und die Nachricht des ursprünglichen Fehlers weitergeben
        throw {
          type: 'http-error',
          status: e.response.status,
          message: e.response.data.message || 'Unknown error',
          name: 'PasswordReset Error',
        }
      } else {
        // Wenn es kein Response-Objekt gibt, handelt es sich um einen anderen Fehler
        throw {
          type: 'unknown',
          message: e.message || 'Unknown error',
          name: 'PasswordReset Error',
        }
      }
    }
  },
  async verifyEmail(
    { rootState },
    { id }
  ): Promise<PyramidPasswordResetResponse> {
    let response
    try {
      const { url, data, config } = fusionAuthEmailVerify({
        id,
      })
      response = await this.$axios.$post(url, data, config)
      return response
    } catch (e) {
      throw { type: 'unknown', name: 'verify email Error' } // eslint-disable-line no-throw-literal
    }
  },
  async passwordResetEnd(
    { rootState },
    { password, id }
  ): Promise<PyramidPasswordResetResponse> {
    let response
    try {
      const { url, data, config } = pyramidPasswordResetEnd({
        locale: rootState.i18n.locale,
        password,
        id,
      })
      response = await this.$axios.$post(url, data, config)
      return response
    } catch (e) {
      throw { type: 'unknown', name: 'PasswordReset Error' } // eslint-disable-line no-throw-literal
    }
  },

  socialSignOnStart(
    _ctx,
    { provider }: { provider: 'google' | 'facebook' | 'apple' }
  ): Promise<string> {
    return new Promise((resolve) => {
      const { identityProviderId } = appConfig.fusionAuth[provider]
      const redirectUri = appConfig.fusionAuth.redirectUri
      const { codeChallenge, codeVerifier } = generatePkcePair()
      cookie.set(appConfig.cookies.pkceVerifier.name, codeVerifier, {
        expires: appConfig.cookies.pkceVerifier.lifetimeDays,
        secure: appConfig.cookies.secure,
      })
      resolve(
        fusionAuthSocialSignOnStartUrl({
          applicationId: appConfig.fusionAuth.applicationId,
          codeChallenge,
          redirectUri,
          identityProviderId,
        })
      )
    })
  },
  async extractEmailFromToken({ commit, dispatch }, { code }) {
    try {
      let response: FusionauthSocialSignOnResponse

      const redirectUri = appConfig.fusionAuth.redirectUri
      const codeVerifier = cookie.get(appConfig.cookies.pkceVerifier.name) ?? ''
      const { url, data, config } = fusionauthSocialSignOn({
        applicationId: appConfig.fusionAuth.applicationId,
        codeVerifier,
        code,
        redirectUri,
      })
      response = await this.$axios.$post(url, data, config)
      // Verwenden Sie die bestehende Funktion, um den Payload zu erhalten
      const tokenContent = await getVerifiedTokenContent(
        response.access_token,
        this.$axios
      )
      // Stellen Sie sicher, dass tokenContent die E-Mail-Adresse enthält
      if (tokenContent && tokenContent.email) {
        // TokenContent erfolgreich extrahiert, E-Mail-Adresse vorhanden
        return tokenContent.email // E-Mail-Adresse zurückgeben
      } else {
        throw new Error('E-Mail-Adresse im Token nicht gefunden.')
      }
    } catch (error) {
      console.error(
        'Fehler beim Extrahieren der E-Mail-Adresse aus dem Token:',
        error
      )
      throw error // Weiterleitung des Fehlers
    }
  },
  async socialSignOnCode(
    { commit, dispatch },
    { code }: { code: string }
  ): Promise<FusionauthSocialSignOnResponse> {
    let response: FusionauthSocialSignOnResponse
    try {
      const redirectUri = appConfig.fusionAuth.redirectUri
      const codeVerifier = cookie.get(appConfig.cookies.pkceVerifier.name) ?? ''
      const { url, data, config } = fusionauthSocialSignOn({
        applicationId: appConfig.fusionAuth.applicationId,
        codeVerifier,
        code,
        redirectUri,
      })
      response = await this.$axios.$post(url, data, config)
      //hier kann ich den login abbrechen
      commit('login', {
        token: response.access_token,
        refreshToken: response.refresh_token,
      })
      commit('setTokenContent', {
        tokenContent: await getVerifiedTokenContent(
          response.access_token,
          this.$axios
        ),
      })
      await Promise.all([
        dispatch('user/getVideoStars', undefined, { root: true }),
        dispatch('user/getActivePlan', undefined, { root: true }),
      ])
    } catch (e) {
      throw { type: 'unknown', name: 'AuthError' } // eslint-disable-line no-throw-literal
    }

    return response
  },
  async code(
    { commit, dispatch },
    { code }
  ): Promise<FusionauthVerifyCodeResponse> {
    let response: FusionauthVerifyCodeResponse
    try {
      const { url, data, config } = fusionauthVerifyCode({ code })
      response = await this.$axios.$post(url, data, config)
      commit('login', {
        mail: response.user.email,
        token: response.token,
        refreshToken: response.refreshToken,
      })
      commit('setTokenContent', {
        tokenContent: await getVerifiedTokenContent(
          response.token,
          this.$axios
        ),
      })
      await Promise.all([
        dispatch('user/getVideoStars', undefined, { root: true }),
        dispatch('user/getActivePlan', undefined, { root: true }),
      ])
    } catch (e) {
      throw { type: 'failed', name: 'AuthError' } // eslint-disable-line no-throw-literal
    }

    return response
  },

  async refresh(
    { commit },
    { refreshToken }
  ): Promise<FusionauthRefreshResponse> {
    let response: FusionauthRefreshResponse
    try {
      const { url, data, config } = fusionauthRefresh({ refreshToken })
      response = await this.$axios.$post(url, data, config)
      commit('login', {
        token: response.token,
        refreshToken: response.refreshToken,
      })
      commit('setTokenContent', {
        tokenContent: await getVerifiedTokenContent(
          response.token,
          this.$axios
        ),
      })
    } catch (e) {
      throw { type: 'unknown', name: 'AuthError' } // eslint-disable-line no-throw-literal
    }

    return response
  },
  logout({ commit }) {
    commit('logout')
  },
  load({ commit }) {
    commit('load')
  },
  subscriptionCompleted({ commit }) {
    commit('subscriptionCompleted')
  },
  async loadLogin({ commit }, { token, refreshToken }) {
    commit('login', {
      token,
      refreshToken,
    })
    commit('setTokenContent', {
      tokenContent: await getVerifiedTokenContent(token, this.$axios),
    })
  },
  async tryAuthenticatedGet(
    { state, dispatch },
    { url, config }: { url: string; config: GetRequestConfig }
  ): Promise<ReturnType<NuxtAxiosInstance['get']>> {
    if (!state.token || !state.tokenContent) throw { type: 'no-login' } // eslint-disable-line no-throw-literal
    try {
      return await this.$axios.get(url, config)
    } catch {
      if (!state.refreshToken) throw { type: 'no-refresh' } // eslint-disable-line no-throw-literal
      await dispatch('refresh', { refreshToken: state.refreshToken })
      return await this.$axios.get(url, config)
    }
  },
  async tryAuthenticatedDelete(
    { state, dispatch },
    { url, config }: { url: string; config: GetRequestConfig }
  ): Promise<ReturnType<NuxtAxiosInstance['delete']>> {
    if (!state.token || !state.tokenContent) throw { type: 'no-login' } // eslint-disable-line no-throw-literal
    try {
      return await this.$axios.delete(url, config)
    } catch {
      if (!state.refreshToken) throw { type: 'no-refresh' } // eslint-disable-line no-throw-literal
      await dispatch('refresh', { refreshToken: state.refreshToken })
      return await this.$axios.delete(url, config)
    }
  },
  async tryAuthenticatedPost(
    { state, dispatch },
    {
      url,
      data,
      config,
    }: { url: string; data: Record<string, unknown>; config: GetRequestConfig }
  ): Promise<ReturnType<NuxtAxiosInstance['post']>> {
    if (!state.token || !state.tokenContent) throw { type: 'no-login' } // eslint-disable-line no-throw-literal
    try {
      return await this.$axios.post(url, data, config)
    } catch {
      if (!state.refreshToken) throw { type: 'no-refresh' } // eslint-disable-line no-throw-literal
      await dispatch('refresh', { refreshToken: state.refreshToken })
      return await this.$axios.post(url, data, config)
    }
  },
}
