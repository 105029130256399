import { isoToLanguage, isoToRegion } from './i18n'

const isoToZendeskLocale = (iso: string) => {
  const region = isoToRegion(iso)
  const language = isoToLanguage(iso)
  if (iso === 'en-US') return 'en-US'
  if (language === 'en') return 'en-gb'
  if (region === 'CH') return `${language}-ch`
  return language
}

export const zendeskInit = (): void => {
  if (!window.zE) {
    // eslint-disable-next-line no-console
    console.error('Unexpected error during ZenDesk initialization')
    return
  }
  window.zE('webWidget', 'hide')
  window.zE('webWidget:on', 'open', () => window.zE!('webWidget', 'show'))
  window.zE('webWidget:on', 'close', () => window.zE!('webWidget', 'hide'))
}

export const zendeskOpen = function (): void {
  if (window.zE) window.zE('webWidget', 'open')
}

export const zendeskClose = function (): void {
  if (window.zE) window.zE('webWidget', 'close')
}

export const zendeskSetLocale = function (iso: string): void {
  if (window.zE) window.zE('webWidget', 'setLocale', isoToZendeskLocale(iso))
}

export const zendeskSnippet = function (region: string): void {

  if (!['US', 'EU', 'UK', 'CH'].includes(region)) return

  /* istanbul ignore next */
  if (region === 'US') {
    const ts = document.createElement('script')
    ts.async = true
    ts.setAttribute('id', 'ze-snippet')
    ts.setAttribute(
      'src',
      `https://static.zdassets.com/ekr/snippet.js?key=726157b9-b479-4777-a21e-2a06ad1f378a`
    )

    ts.addEventListener('load', (_event) => {
      window.dispatchEvent(new CustomEvent('zendeskLoaded'))
    })
    document.head.appendChild(ts)
  } else if (region === 'EU' || region === 'UK'  || region === 'CH') {
    const ts = document.createElement('script')
    ts.async = true
    ts.setAttribute('id', 'ze-snippet')
    ts.setAttribute(
      'src',
      `https://static.zdassets.com/ekr/snippet.js?key=82a13ca8-d93b-4598-915c-1f4fbe3800fc`
    )

    ts.addEventListener('load', (_event) => {
      window.dispatchEvent(new CustomEvent('zendeskLoaded'))
    })
    document.head.appendChild(ts)
  }
}
