import config, { Region } from './../app.config'

export const defaultRegion = config.defaultRegion
export const regions = config.regions.map((region) => region.id)
export const regionsToDefaultLanguages = config.regions.reduce(
  (acc, region) => {
    acc[region.id] = region.defaultLanguage
    return acc
  },
  {} as {
    [region: Region['id']]: string
  }
)
export const regionsToLanguages = config.regions.reduce(
  (acc, region) => {
    acc[region.id] = region.languages
    return acc
  },
  {} as {
    [region: Region['id']]: Region['languages']
  }
)

const ISO_SEPARATOR = '-'

export const isValidLocale = (locale: string): boolean => {
  if (locale.includes(ISO_SEPARATOR)) {
    const [language, region] = locale.split(ISO_SEPARATOR)
    return (
      regions.includes(region) &&
      !!regionsToLanguages[region].find((l) => l === language)
    )
  } else {
    return false
  }
}

export const isoToSlug = (iso: string): string => iso.toLowerCase()

export const isoKeysToSlugs = <T>(o: Record<string, T>): Record<string, T> =>
  Object.fromEntries(
    Object.entries(o).map(([key, val]) => [isoToSlug(key), val])
  )

export const slugToIso = (slug: string): string =>
  slug
    .split('-')
    .map((c: string, i: number) => (i === 1 ? c.toUpperCase() : c))
    .join('-')

export const makeIso = (region: string | undefined, language: string): string =>
  `${language}${region ? ISO_SEPARATOR : ''}${region ?? ''}`

export const isoToLanguage = (iso: string): string =>
  iso.split(ISO_SEPARATOR)[0]

export const isoToRegion = (iso: string): string => {
  const region = iso.split(ISO_SEPARATOR)[1]
  return region === 'LIE' ? 'CH' : region
}

export const isoToApolloClient = (iso: string): string =>
  `shopify${iso.replace('-', '')}`

export const isoToCurrency = (iso: string): string => {
  const region = isoToRegion(iso) as Region['id']
  return config.regions.find((r) => r.id === region)!.currency
}

// ['en-CH', 'de-CH', 'de-DE' ...]
export const isos = regions
  .map((region) =>
    regionsToLanguages[region].map((language) => makeIso(region, language))
  )
  .reduce((acc, arr) => acc.concat(arr), [])

export const userLanguageFromRegion = (region: string): string => {
  const userLanguage = process.client
    ? isoToLanguage(window.navigator.language)
    : ''
  return regionsToLanguages[region].includes(userLanguage)
    ? userLanguage
    : regionsToDefaultLanguages[region]
}

export const userSlugFromRegion = (region: string): string => {
  const iso = makeIso(region, userLanguageFromRegion(region))
  return isoToSlug(iso)
}

export const formatPrice = (
  price: number,
  region: string,
  language: string,
  currency: string
): string => {
  return Number(price).toLocaleString(
    `${language}-${region.replace('EU', 'DE')}`,
    {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  )
}
